<template lang="pug">
div
  .page-header
    .row
      nav-pills
      .col-md-5
        h1 {{cliente}}
      .col-md-7
  form(@submit.prevent='gravar')
    vue-element-loading(:active='isActive', spinner='bar-fade-scale', color='#428BCA')
    .form-horizontal
      .form-group
        label.col-sm-3.control-label.no-padding-right(for='contrato') Contrato
        .col-sm-4
          input.form-control.col-xs-10.col-sm-3(required='', v-model='venda.contrato', placeholder="Contrato")
      .form-group
        select-form(request='true', v-model='venda.tipo_emprestimo', name='Tipo de Emprestimo', url='/tipo-emprestimo', id='id', titulo='nome', layout='true', :required='true')
      .form-group
        select-form(v-model='venda.orgao', name='Orgão', url='/orgao', id='id', titulo='nome', layout='true', :required='true')
      .form-group
        label.col-sm-3.control-label.no-padding-right(for='valor_financ') Valor do Financiamento
        .col-sm-4
          money.form-control.col-xs-10.col-sm-3(v-bind="money", required='', v-model='venda.valor_financ', placeholder="Valor do Financiamento")
      legend Parcelamento
        .form-group
          label.col-sm-3.control-label.no-padding-right(for='parc_valor') Valor do Parcelamento
          .col-sm-4
            money.form-control.col-xs-10.col-sm-3(v-bind="money", required='', v-model='venda.parc_valor', placeholder="Valor do Parcelamento")
        .form-group
          label.col-sm-3.control-label.no-padding-right(for='parc_qtd_parcelas') Quantidade de parcelas
          .col-sm-4
            input.form-control.col-xs-10.col-sm-3(required='', v-model='venda.parc_qtd_parcelas', placeholder="Quantidade de parcelas")
        .form-group
          label.col-sm-3.control-label.no-padding-right(for='parc_qtd_taxa') Taxa
          .col-sm-4
            input.form-control.col-xs-10.col-sm-3(required='', v-model='venda.parc_qtd_taxa', placeholder="Taxa")
        .form-group
          label.col-sm-3.control-label.no-padding-right(for='parc_qtd_banco') Banco
          .col-sm-4
            input.form-control.col-xs-10.col-sm-3(required='', v-model='venda.parc_qtd_banco', placeholder="Banco")

      .form-group
        label.col-sm-3.control-label.no-padding-right(for='obs')  Obs
        .col-sm-9
          textarea.form-control.autosize(v-model='venda.obs', placeholder='Obs')
      legend Dados Bancários
        .form-group
          select-form(request='true', v-model='venda.tipo_conta', name='Tipo de Conta', url='/tipo-conta', id='id', titulo='nome', layout='true', :required='true')
        .form-group
          label.col-sm-3.control-label.no-padding-right(for='banco') Banco
          .col-sm-4
            input.form-control.col-xs-10.col-sm-3(required='', v-model='venda.banco', placeholder="Banco")
        .form-group
          label.col-sm-3.control-label.no-padding-right(for='agencia') Agencia
          .col-sm-4
            input.form-control.col-xs-10.col-sm-3(required='', v-model='venda.agencia', placeholder="Agencia")
        .form-group
          label.col-sm-3.control-label.no-padding-right(for='conta') Conta
          .col-sm-4
            input.form-control.col-xs-10.col-sm-3(required='', v-model='venda.conta', placeholder="Conta")
      .form-group
        label.col-sm-3.control-label.no-padding-right(for='dt_venda')  Data da Venda
        .col-sm-4
          flat-pickr.col-xs-10.col-sm-3(
            v-model="venda.dt_venda",
            :config="config",
            class="form-control",
            placeholder="Selecione a Data da Venda",
            name="date", required)
      .form-group
        select-form(request='true', v-model='venda.status_id', name='Status', url='/status/vendas', id='id', titulo='nome', layout='true', :required='true')
      .form-group(v-if='user.id_grupo == 1 || user.id_grupo == 3')
        select-form(request='true', v-model='venda.usu_id2', name='Vendedor', url='/usuarios', id='id', titulo='name', layout='true', :required='true')
      .modal-footer
        div(v-if='$route.params.idVenda')
          button.btn.btn-warning(type='submit', @click='envio = "Cliente"')
            | Atualizar -&nbsp;
            i.fa.fa-address-book-o
            |  Cliente
          button.btn.btn-primary(type='submit', @click='envio = "Agenda"')
            | Atualizar -&nbsp;
            i.fa.fa-calendar-check-o
            |  Agenda
          button.btn.btn-success(type='submit', @click='envio = "Venda"')
            | Atualizar -&nbsp;
            i.fa.fa-cart-plus
            |  Venda
        div(v-else)
          button.btn.btn-warning(type='submit', @click='envio = "Cliente"')
            | Cadastrar -&nbsp;
            i.fa.fa-address-book-o
            |  Cliente
          button.btn.btn-primary(type='submit', @click='envio = "Agenda"')
            | Cadastrar -&nbsp;
            i.fa.fa-calendar-check-o
            |  Agenda
          button.btn.btn-success(type='submit', @click='envio = "Venda"')
            | Cadastrar -&nbsp;
            i.fa.fa-cart-plus
            |  Venda
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import VueElementLoading from 'vue-element-loading'
import {Portuguese} from 'flatpickr/dist/l10n/pt'
import 'flatpickr/dist/flatpickr.css'
import {Money} from 'v-money'
import navPills from '@/components/NavPills'
import CartForm from '@/components/form/CartForm'
import SelectForm from '@/components/form/SelectForm'
import req from '@/components/request'
import { erro } from '@/components/Message'
import Permission from '@/mixins/Permission'

export default {
  components: {
    navPills,
    CartForm,
    SelectForm,
    flatPickr,
    VueElementLoading,
    Money
  },
  mixins: [Permission],
  computed: {
    ...mapGetters([
      'user'
    ]),
    total () {
      let totalgeral = 0
      for (var idx in this.produtosSelecionados) {
        let currentItem = this.produtosSelecionados[idx]
        totalgeral += parseInt(currentItem.quant) * currentItem.preco
      }
      return totalgeral
    }
  },
  data () {
    return {
      envio: 'Venda',
      isActive: false,
      cliente: '',
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altInput: true,
        altFormat: 'd F Y',
        dateFormat: 'Y-m-d',
        locale: Portuguese
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      },
      venda: {
        contrato: '',
        obs: null,
        tipo_emprestimo: '',
        orgao: '',
        valor_financ: '',
        parc_valor: '',
        parc_qtd_parcelas: null,
        parc_qtd_taxa: null,
        parc_qtd_banco: null,
        status_id: null,
        banco: null,
        agencia: null,
        conta: null,
        tipo_conta: null,
        vendedor: null,
        usuario_id: 0,
        clientes_id: 0,
        dt_venda: null
      },
      produtosSelecionados: [],
      select: []
    }
  },
  methods: {
    ...mapActions([
      'getLogsTotal'
    ]),
    getVenda () {
      req(`/vendas/${this.$route.params.idVenda}`).then(resp => {
        resp.valor_financ = resp.valor_financ ? parseFloat(resp.valor_financ) : 0
        resp.parc_valor = resp.parc_valor ? parseFloat(resp.parc_valor) : 0
        this.venda = resp
        this.venda.usu_id2 = resp.usu_id
        this.isActive = false
      }).catch(() => {
        erro()
      })
    },
    getCliente () {
      req(`/clientes/nome/${this.$route.params.id}`).then(resp => {
        this.cliente = resp.cliente.toUpperCase()
      }).catch(() => {
        erro()
      })
    },
    gravar () {
      let body = this.venda
      body.usuario_id = this.venda.usu_id2 ? this.venda.usu_id2 : this.user.id
      body.clientes_id = this.$route.params.id
      body.USER = this.user.id

      if (this.$route.params.idVenda) {
        this.$axios.put(`/vendas/${this.$route.params.idVenda}`, body).then(() => {
          this.getLogsTotal(this.user.id)
          switch (this.envio) {
            case 'Agenda':
              this.$router.push(`/sistema/clientes/${this.$route.params.id}/agenda`)
              return
            case 'Vendas':
              this.$router.push(`/sistema/vendas`)
              return
            case 'Cliente':
              this.$router.push(`/sistema/clientes/${this.$route.params.id}`)
            // eslint-disable-next-line no-fallthrough
            default:
              this.$router.push(`/sistema/vendas`)
          }
        })
      } else {
        this.$axios.post('/vendas', body).then(() => {
          this.getLogsTotal(this.user.id)
          switch (this.envio) {
            case 'Agenda':
              this.$router.push(`/sistema/clientes/${this.$route.params.id}/agenda`)
              return
            case 'Vendas':
              this.$router.push(`/sistema/vendas`)
              return
            case 'Cliente':
              this.$router.push(`/sistema/clientes/${this.$route.params.id}`)
            // eslint-disable-next-line no-fallthrough
            default:
              this.$router.push(`/sistema/vendas`)
          }
        })
      }
    },
    selecionar (produto) {
      this.produtosSelecionados = produto
    },
    setCategoria (categoria) {
      this.venda.categoria_id = categoria
    },
    verifPermissao () {
      if (this.$route.params.idVenda) {
        if (!this.ACTION.ev) {
          this.$router.push('/erro')
        }
      }
    }
  },
  created () {
    this.getCliente()
  },
  mounted () {
    this.verifPermissao()
    if (this.$route.params.idVenda) {
      this.isActive = true
      this.getVenda()
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
